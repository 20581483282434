import React from 'react';
import instaImage from '../assets/img/icons8-instagram-50.png';
import faceImage from '../assets/img/icons8-facebook-50.png';

const Socials = () => {
  return (
    <div className="flex space-x-4">
      <div className="relative rounded-lg overflow-hidden w-12 h-12 flex justify-center items-center hover:border-black border-transparent transition duration-300">
        <a href="https://www.instagram.com/heytwistedsugar/?hl=en" target="_blank" rel="noopener noreferrer">
          <img src={instaImage} alt="Instagram Icon" className="w-8 h-8" />
        </a>
      </div>
      <div className="relative rounded-lg overflow-hidden w-12 h-12 flex justify-center items-center hover:border-black border-transparent transition duration-300">
        <a href="https://www.facebook.com/heytwistedsugar/" target="_blank" rel="noopener noreferrer">
          <img src={faceImage} alt="Facebook Icon" className="w-8 h-8" />
        </a>
      </div>
    </div>
  );
};

export default Socials;
