import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import sanityClient, { urlFor } from '../sanityClient'; // Import Sanity client
import './productPromotion.css'

const ProductPromotion = () => {
  const [products, setProducts] = useState([]); // State to hold products
  const { ref, inView } = useInView({ triggerOnce: true });

  // Function to fetch products from Sanity
  const fetchProducts = async () => {
    try {
      const productsData = await sanityClient.fetch('*[_type == "product"]');
      setProducts(productsData);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {
    fetchProducts(); // Fetch products on component mount
  }, []);

  return (
    <div ref={ref}>
      <motion.section className="relative z-10 overflow-hidden box-shadow-inset" style={{ background: 'linear-gradient(to bottom, #ffffff, rgb(187 188 191)' }}>
        <div className="container mx-auto">
          <div className="flex flex-col items-center mt-8 relative">
            <div className="text-center">
              <div className="pretitle text-black">Our Specialties</div>
              <h2 className="h2 capitalize text-black">You know you need it!</h2>
            </div>
            <div className="mt-8" style={{ width: '100%', margin: '0 7rem' }}>
            
              <Carousel
                additionalTransfrom={0}
                arrows
                autoPlay
                autoPlaySpeed={3000}
                centerMode
                className=""
                containerClass="container mx-auto"
                customTransition="all 1s linear"
                dotList={false} // Hide pagination dots
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside
                responsive={{
                  desktop: {
                    breakpoint: { max: 3000, min: 1024 },
                    items: 2,
                    partialVisibilityGutter: 40,
                  },
                  tablet: {
                    breakpoint: { max: 1024, min: 464 },
                    items: 2,
                    partialVisibilityGutter: 30,
                  },
                  mobile: {
                    breakpoint: { max: 464, min: 0 },
                    items: 1,
                    partialVisibilityGutter: 30,
                    imageScale: 1.5, // Increase image size on mobile
                  },
                }}
                showDots={false} // Hide pagination dots
                sliderClass=""
                slidesToSlide={1}
                swipeable
                style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }} // Add shadow to the top and bottom
              >
                {/* Map over products and display them */}
                {products.map((product) => (
                  <div key={product._id} className="px-4">
                    <img
                      src={urlFor(product.image)
                        .width(500) // Set a larger width for desktop devices
                        .height(400) // Set a larger height for desktop devices
                        .url()}
                      alt={product.title}
                      className="h-full w-full object-contain"
                    />
                    <div className="mt-4 mb-8 text-center">
                      <h2 className="text-black text-lg font-bold h3">{product.title}</h2>
                      <p className="text-white mt-1">{product.description}</p>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </motion.section>
    </div>
  );
};

export default ProductPromotion;
