import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import client from '../sanityClient'; // Import the Sanity client instance
import logoImage from '../assets/img/white.png';
import './Nav.css'; // Import the CSS file
import instaImagedark from '../assets/img/icons8-instagram-50.png';
import faceImagedark from '../assets/img/icons8-facebook-50.png';

const Nav = () => {
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const fetchHeaderNavData = async () => {
      try {
        const data = await client.fetch('*[_type == "headernav"][0]');
        if (data && data.menuItems) {
          setMenuItems(data.menuItems);
        }
      } catch (error) {
        console.error('Error fetching header navigation data:', error);
      }
    };

    fetchHeaderNavData();
  }, []);

  return (
    <nav className="text-center">
    <motion.div className="mt-8" initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.2, duration: 0.8 }}> {/* Adding margin to the top of the content */}
      {/* Logo Section */}
      <motion.div className="flex justify-center mb-4" initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.2, duration: 0.8 }}>
        {/* Your logo component here */}
        <img src={logoImage} alt="Logo" className="logo-class-nav h-[4rem]" />
      </motion.div>
      {/* Menu Items Container */}
      <div className="bg-gray-200 rounded-lg p-4 mb-4 w-full max-w-[90%] mx-auto position-relative shadow-xl">
          {/* Background Image */}
        <div className="bg-with-background-image">

        {/* Menu Items */}
        <ul className="flex flex-col items-center mt-1 straight-items">
          {/* Map over menuItems and render list items */}
          {menuItems.map((item, index) => (
            <motion.li key={index} className="mb-4" initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: index * 0.1, duration: 0.6 }}>
              <a
                href={item.link}
                className="block w-full py-2 px-4 text-xl font-semibold text-black hover:text-white hover:bg-black transition-colors duration-300 rounded-md font-bold" // Changed initial text color to black and hover text color to white
                style={{ fontFamily: '"Gotham Bold", Helvetica, Arial, sans-serif' }}
              >
                {item.name}
              </a>
            </motion.li>
          ))}
        </ul>
        </div>
      </div>
    </motion.div>
    {/* Buttons */}
    <div className="flex flex-col items-center mt-8 ">
      <a href="" className="primary-cta shadow-lg  max-w-[21rem]">Log in to Rewards <span className='text-xs'>Coming Soon!</span></a>
      <a href="https://order.online/business/TwistedSugar-121912?utm_source=sdk&visitorId=18bda5615d37d2b1f" className="primary-cta shadow-lg  max-w-[21rem]">Order Online</a>
    </div>
    <div className='flex items-center justify-center mt-8'>
    <div className="flex space-x-4">
      <div className="relative rounded-lg overflow-hidden w-12 h-12 flex justify-center items-center hover:border-black border-transparent transition duration-300">
        <a href="https://www.instagram.com/heytwistedsugar/?hl=en" target="_blank" rel="noopener noreferrer">
          <img src={instaImagedark} alt="Instagram Icon" className="w-8 h-8" />
        </a>
      </div>
      <div className="relative rounded-lg overflow-hidden w-12 h-12 flex justify-center items-center hover:border-black border-transparent transition duration-300">
        <a href="https://www.facebook.com/heytwistedsugar/" target="_blank" rel="noopener noreferrer">
          <img src={faceImagedark} alt="Facebook Icon" className="w-8 h-8" />
        </a>
      </div>
    </div>
    </div>
  </nav>
  );
};

export default Nav;
