import React from 'react';
import { About, CateringSection, Hero, Locations, ProductPromotion,  } from '../components'; // Import all components from the index file

const Homepage = () => {
  return (
    <div>
      <Hero />
      <About />
      <CateringSection />
      <ProductPromotion />
      <Locations />
    </div>
  );
};

export default Homepage;
