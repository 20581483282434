import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './locations.css';

const Locations = () => {
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    const loadStoreRocketScript = () => {
      // Check if the script is already loaded
      if (document.getElementById('storeRocketScript')) return;

      const script = document.createElement('script');
      script.id = 'storeRocketScript';
      script.src = '//cdn.storerocket.io/widget.js';
      script.async = true;

      script.onload = () => {
        window.StoreRocket.init({
          selector: '.storerocket-store-locator',
          account: 'OdJEOEdpWE'
        });
      };

      // Set cache-control header to optimize caching
      script.setAttribute('cache-control', 'public, max-age=31536000'); // 1 year

      document.body.appendChild(script);
    };

    if (inView) {
      loadStoreRocketScript();
    }
  }, [inView]);

  return (
    <div ref={ref} className='map-background' id='locations'>
      <motion.section
        className="min-h-[70vh] flex flex-col justify-center items-center"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
        transition={{ duration: 0.5 }}
      >
        <div className="text-center">
          <h1 className="font-bold mb-8 mt-5 h2 text-black">Find your Store</h1>
        </div>
        {/* Placeholder for Store Rocket widget */}
        <div className="w-full max-w-screen-lg storerocket-store-locator z-[5]" />
      </motion.section>

    </div>
  );
};

export default Locations;
