import React, { useEffect, useState } from 'react';
import client, { urlFor } from '../sanityClient';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import homeCater from '../assets/img/pexels-fwstudio-129731.jpg';
import "./cateringSection.css"

const CateringSection = () => {
  const [cateringData, setCateringData] = useState(null);
  const [scrollY, setScrollY] = useState(0);
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    const fetchCateringData = async () => {
      try {
        const result = await client.fetch(`*[_type == "cateringSection"][0]`);
        setCateringData(result);
      } catch (error) {
        console.error('Error fetching catering section data:', error);
      }
    };

    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    fetchCateringData();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="relative h-screen-custom box-shadow-inset-cater" ref={ref}>
      {cateringData && (
        <>
          {/* Background Images with Parallax Effect */}
          <div className="absolute top-0 left-0 w-full h-screen-custom overflow-hidden box-shadow cater-background">
             
            <div
              className="absolute top-0 left-0 w-full h-full"
              style={{ background: 'rgba(0, 0, 0, 0.5)', }} // Dark overlay
            />
          </div>

          {/* Content Section with Smoother Fade-in */}
          <motion.section className="min-h-[620px] relative z-10" style={{ opacity: Math.min(1, scrollY / 100) }}>
            <div className="container mx-auto min-h-[620px]">
              <div className="min-h-[620px] flex flex-col lg:flex-row items-center">
                <div className="flex-1 text-center lg:text-left mr-4">
                  <div className="pretitle text-white">{cateringData.pretitle}</div>
                  <h2 className="h2 capitalize text-white">{cateringData.title}</h2>
                  {/* Adding content from cateringData.content */}
                  <p className="p-box">
                    {cateringData.content}
                  </p>
                  <a class="btn capitalize lg:mx-0 roboto-font btn-shadow mt-5 w-[50%]" href='#locations'>Find your location</a>
                </div>
                <div className="-mb-[100px] -mr-[186px] z-10">
                  <motion.img
                    src={urlFor(cateringData.cateringImage).width(800).url()}
                    alt="Catering Image"
                    initial={{ opacity: 0, x: '100vw' }}
                    animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : '100vw' }}
                    transition={{ type: 'spring', stiffness: 20, duration: 0.5, delay: 0.3 }}
                    style={{ borderRadius: '10px', border: '3px solid #ccc', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
                  />
                </div>
              </div>
            </div>
          </motion.section>

        </>
      )}
    </div>
  );
};

export default CateringSection;
