import React from 'react';
import { Helmet } from 'react-helmet';

const Franchise = () => {
  return (
    <>
      <Helmet>
        <title>Twisted Sugar - Franchising Inquiry</title>
        <meta name="description" content="Become a part of Twisted Sugar! Fill out our franchising inquiry form to learn more about joining our soda and cookie shop family." />
        <meta name="keywords" content="Twisted Sugar, Franchising, Soda Shop, Cookie Shop, Franchise Opportunities, Business Opportunities" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://twistedsugar.net/franchising-inquiry" />
      </Helmet>
      <div className="bg-white min-h-screen flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8"
           style={{ marginTop: '5%' }}
           >
        <div className="max-w-3xl w-full space-y-8 text-center"
             style={{ marginTop: '10%' }}>
          <h1 className="text-4xl font-bold text-gray-900">Join the Twisted Sugar Family</h1>
          <p className="text-lg text-gray-600">
            We're excited to share more about our franchising opportunities. Please fill out the form below to get started!
          </p>
          <div className="shadow-xl rounded-lg overflow-hidden">
            <iframe
              src="https://forms.monday.com/forms/embed/51aab79b8a4e9f1466d23734a0bdf7ab?r=use1"
              width="100%"
              height="1600" // Adjusted to fit the screen with margin
              style={{ border: '0', boxShadow: '5px 5px 56px 0px rgba(0,0,0,0.25)' }}
              title="Twisted Sugar Franchising Inquiry"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default Franchise;
