import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { urlFor } from '../sanityClient'; // Import the urlFor function from your Sanity client
import Nav from '../components/Nav';
import Socials from './Socials';
import { staggerContainer, fadeIn } from '../variants';
import client from '../sanityClient';
import './header.css'
import topimage from '../assets/img/newtop.png'

const headerVariants = {
  hidden: {
    padding: '70px 0 14px 0',
    background: 'none'
  },
  show: {
    padding: '90px 0 4px 0',
    backgroundImage: `url(${topimage})`, // Using backticks to interpolate the variable
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    transition: {
      type: 'spring'
    }
  }
};

// Media query for mobile and tablet screens
const mobileTabletMediaQuery = '@media only screen and (min-width: 768px)';

// Override background image for mobile and tablet screens
headerVariants.show = {
  ...headerVariants.show,
  [mobileTabletMediaQuery]: {
    backgroundImage: 'none',
    background: 'rgba(0, 0, 0, 0.92)' // Black background
  }
};

export const navVariants = {
  hidden: {
    x: '-100%',
  },
  show: {
    x: 0,
    transition: {
      type: 'tween',
      duration: 0.9, // Adjust duration for slower animation
      ease: 'easeInOut',
    },
  },
};

const Header = () => {
  const [isActive, setIsActive] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');
  const [nav, setNav] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.scrollY > 50 ? setIsActive(true) : setIsActive(false);
    });

    // Fetch logo data from Sanity
    const fetchHeaderData = async () => {
      try {
        // Replace 'logo' with your schema name for the logo
        const headerData = await client.fetch('*[_type == "headernav"][0]');
        if (headerData && headerData.logo) {
          // Generate the URL for the logo image
          const imageUrl = urlFor(headerData.logo).width(900).url();
          setLogoUrl(imageUrl);
        }
      } catch (error) {
        console.error('Error fetching logo:', error);
      }
    };

    fetchHeaderData();
  }, []);

  return (
    <motion.header
      variants={headerVariants}
      initial="show"
      animate={isActive ? 'show' : ''}
      className=" fixed w-full max-w-[2000px] z-50 pt-8 bg-black mobile-dif"
    >
      <motion.div
        className="container mx-auto bg-black"
        variants={staggerContainer(0.3, 1)}
        initial="hidden"
        animate={'show'}
      >
        <div className="flex justify-between items-center px-4 lg:px-0 relative text-white ">
          <motion.div
            variants={fadeIn('down', 'tween', 1.2, 1.4)}
            className={`${
              nav ? 'gap-y-0' : 'gap-y-2'
            } flex flex-col items-center justify-center w-12 h-12 p-3 order-2 lg:order-none cursor-pointer border-2 rounded-full`}
            onClick={() => setNav(!nav)}
            style={{ zIndex: 60 }} 
          >
            <motion.div
              initial={{
                rotate: 0,
              }}
              animate={{ rotate: nav ? -45 : 0, translateY: nav ? 2 : 0 }}
              className="w-full h-[2px] bg-white"
            ></motion.div>
            <motion.div
              initial={{
                rotate: 0,
              }}
              animate={{ rotate: nav ? 45 : 0 }}
              className="w-full h-[2px] bg-white"
            ></motion.div>
          </motion.div>
          <motion.div variants={fadeIn('down', 'tween', 1.2, 1.4)}>
            <a href="#">
              <img
                className= {`${
                  isActive ? 'w-[50px] h-[70px] only-mobile' : 'w-[50px] h-[70px] only-mobile'
                }`}
                src={logoUrl} // Use the logo URL here
                alt="Logo"
              />
            </a>
          </motion.div>
          <motion.div
            className="hidden lg:flex"
            variants={fadeIn('down', 'tween', 1.4, 1.4)}
          >
            <Socials />
          </motion.div>
          <AnimatePresence>
            {nav && (
              <motion.div
                key="nav-menu"
                variants={navVariants}
                initial="hidden"
                animate="show"
                exit="hidden"
                className="bg-black fixed top-0 left-0 h-screen w-full md:w-[50%] lg:w-[25%] z-60 overflow-hidden border-r-4 border-black"
                style={{ zIndex: 70 }} // Adjust the z-index
              >
                <Nav />
                <motion.div
                  className="cursor-pointer absolute top-4 right-4"
                  onClick={() => setNav(false)}
                >
                 <motion.div
            variants={fadeIn('down', 'tween', 1.2, 1.4)}
            className={`${
              nav ? 'gap-y-0' : 'gap-y-2'
            } flex flex-col items-center justify-center w-12 h-12 p-3 order-2 lg:order-none cursor-pointer border-2 rounded-full`}
            onClick={() => setNav(!nav)}
            style={{ zIndex: 60 }} 
          >
            <motion.div
              initial={{
                rotate: 0,
              }}
              animate={{ rotate: nav ? -45 : 0, translateY: nav ? 2 : 0 }}
              className="w-full h-[2px] bg-white"
            ></motion.div>
            <motion.div
              initial={{
                rotate: 0,
              }}
              animate={{ rotate: nav ? 45 : 0 }}
              className="w-full h-[2px] bg-white"
            ></motion.div>
          </motion.div>
                  
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </motion.div>
      {/* Overlay to gray out the rest of the screen */}
      <AnimatePresence>
        {nav && (
          <motion.div
            key="overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed top-0 left-0 right-0 bottom-0 bg-gray-300/50 z-40"
            onClick={() => setNav(false)} // Close the menu when clicked outside
          />
        )}
      </AnimatePresence>
    </motion.header>
  );
};

export default Header;
