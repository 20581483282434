import React, { useEffect, useState } from 'react';
import client, { urlFor } from '../sanityClient';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import arrows from '../assets/img/giphy-arrows.gif'

const About = () => {
  const [aboutCTAData, setAboutCTAData] = useState(null);
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    const fetchAboutCTAData = async () => {
      try {
        const result = await client.fetch(`*[_type == "aboutCTA"][0]`);
        setAboutCTAData(result);
      } catch (error) {
        console.error('Error fetching about CTA data:', error);
      }
    };

    fetchAboutCTAData();
  }, []);

  return (
    <div ref={ref} id='about'>
      {aboutCTAData && (
        <motion.section
          className='min-h-[620px] shadow-fade-white'
          initial={{ x: '100vw' }}
          animate={{ x: inView ? 0 : '100vw' }}
          transition={{ type: 'spring', stiffness: 20, duration: 0.5 }}
          style={{
            boxShadow: 'rgba(255 254 254 / 99%) 0px -13px 5px 10px, rgba(0, 0, 0, 0.1) 0px -10px 10px -10px' // Add inner box shadowing
          }}
        >
          <div className='container mx-auto min-h-[620px] bg-slate-50 '>
            <div className='min-h-[620px] flex flex-col lg:flex-row items-center'>
              <div className='flex-1 text-center lg:text-left diamond-background'>
                <div className='pretitle'>{aboutCTAData.pretitle}</div>
                <h2 className='h2 capitalize'>{aboutCTAData.title}</h2>
                <p className='mb-8 max-w-[560px]'>{aboutCTAData.subtitle}</p>
                <div className="flex justify-center lg:justify-start space-x-4">
                  {aboutCTAData.btnText1 && (
                    <a className='btn capitalize lg:mx-0 roboto-font btn-shadow' href={aboutCTAData.btnText1.href}>{aboutCTAData.btnText1.text}</a>
                  )}
                  {aboutCTAData.btnText2 && (
                    <a className='btn capitalize lg:mx-0 roboto-font btn-shadow' href={aboutCTAData.btnText2.href}>{aboutCTAData.btnText2.text}</a>
                  )}
                </div>
              </div>
              <div className="flex justify-center items-center mt-5 mb-5">
                <div className="flex">
                  <div className="relative mr-[2rem] flex-1">
                    <h2 className="text-center h3 mb-[2rem] leading-[3rem]">{aboutCTAData.cookieOfTheMonth1.title}</h2>
                    <motion.img
                      src={urlFor(aboutCTAData.cookieOfTheMonth1.image).width(200).url()} // Half the original size
                      alt="Cookies on plate"
                      initial={{ opacity: 0, x: '-100vw' }}
                      animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : '-100vw' }}
                      transition={{ type: 'spring', stiffness: 20, duration: 0.5, delay: 0.3 }}
                      className="mx-auto" // Center the image horizontally
                      style={{ maxWidth: '100%' }} // Ensure image width doesn't exceed container width
                    />
                  </div>
                  <div className="relative ml-[2rem] flex-1">
                    <motion.img
                      src={urlFor(aboutCTAData.cookieOfTheMonth2.image).width(200).url()} // Half the original size
                      alt="Cookies on plate"
                      initial={{ opacity: 0, x: '100vw' }}
                      animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : '100vw' }}
                      transition={{ type: 'spring', stiffness: 20, duration: 0.5, delay: 0.3 }}
                      className="mx-auto" // Center the image horizontally
                      style={{ maxWidth: '100%' }} // Ensure image width doesn't exceed container width
                    />
                    <h2 className="text-center h3 mt-[2rem] leading-[3rem]">{aboutCTAData.cookieOfTheMonth2.title}</h2>
                  </div>
                </div>
              </div>







            </div>
          </div>
        </motion.section>
      )}
    </div>
  );
};

export default About;
