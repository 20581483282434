import React, { useEffect, useState } from 'react';
import Header from './Header';
import { motion } from 'framer-motion';
import client, { urlFor } from '../sanityClient'; // Import the Sanity client instance and urlFor function
import { fadeIn, staggerContainer } from '../variants';

const Hero = () => {
  const [heroData, setHeroData] = useState(null);

  useEffect(() => {
    // Fetch hero data from Sanity
    const fetchHeroData = async () => {
      try {
        const data = await client.fetch('*[_type == "header"][0]'); // Fetching the first header document
        setHeroData(data);
      } catch (error) {
        console.error('Error fetching hero data:', error);
      }
    };

    fetchHeroData();
  }, []);

  return (
    <section
      className='min-h-[980px] bg-auto bg-right'
      style={heroData ? {
        backgroundImage: `url(${urlFor(heroData.heroImage).width(1920).url()})`,
        backgroundSize: 'auto', // Set background size to cover
      } : {}}
    >
        <Header />
      {heroData && (
        <div className='container mx-auto min-h-[980px] flex justify-center items-center'>
          {/* Render hero content */}
          <motion.div 
            variants={staggerContainer(0.3, 1)}
            initial="hidden"
            whileInView={"show"}
            className='text-center flex flex-col items-center'>
            <motion.div 
            variants={fadeIn('down', 'tween', 0.2, 1.1)}
            className="text-white text-[24px] lg:text-[28px] font-primary  lg:font-medium mb-20">
                {/* Render the image */}
                <motion.img 
                  src={urlFor(heroData.titleImage).width(600).url()} // Adjust width as needed
                  alt="Twisted Sugar Cookies, Soda's and more!"
                  variants={fadeIn('down', 'tween', 0.2, 1.1)}
                  style={{ width: '100%' }}
                  className='mb-[7em]'
                />
            </motion.div>
            <motion.h1 
            className='h1 mb-5'
            variants={fadeIn('down', 'tween', 0.3, 1.1)}>
                {heroData.title}
            </motion.h1>
            <motion.p
            variants={fadeIn('down', 'tween', 0.4, 1.1)} 
            className="text-white max-w-[540px] mb-8"
            style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}
          >
            {heroData.subtitle}
          </motion.p>

          <motion.div variants={fadeIn('down', 'tween', 0.5, 1.1)}>
            <button className='btn' style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 1)' }}><a href='https://order.online/business/TwistedSugar-121912?utm_source=sdk&visitorId=18bda5615d37d2b1f'>
              {heroData.btnText}
              </a>
            </button>
          </motion.div>
          </motion.div>
        </div>
      )}
    </section>
  );
};

export default Hero;
