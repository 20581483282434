import React from 'react'

const joinOurTeam = () => {
  return (
    <div>
       <iframe
        src="https://twistedsugar.net/join-our-team/"
        title="Twisted Sugar Franchising"
        style={{ width: '100%', height: '100vh', border: 'none' }}
      ></iframe>
    </div>
    
  )
}

export default joinOurTeam
