import React from 'react';
import footerlogo from '../assets/img/white.png';

const Footer = () => {
  return (
    <div>
      <footer className="bg-black ">
        <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
          <div className="md:flex md:justify-between">
            <div className="mb-6 md:mb-0">
              <a href="https://flowbite.com/" className="flex items-center">
                <img src={footerlogo} className="h-[65px] me-3" alt="Twisted Sugar Logo" />
              </a>
            </div>
            <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
              <div>
                <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white">Resources</h2>
                <ul className="text-white font-medium">
                  <li className="mb-4">
                    <a href="https://twistedsugar.net/nutrition/" className="hover:underline">Nutrition</a>
                  </li>
                  <li>
                    <a href="#about" className="hover:underline">About Us</a>
                  </li>
                  <li>
                    <a href="https://twistedsugar.net/contact/" className="hover:underline ">Contact Us</a>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white">Follow us</h2>
                <ul className="text-white font-medium">
                  <li className="mb-4">
                    <a href="https://www.facebook.com/heytwistedsugar/" className="hover:underline ">Facebook</a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/heytwistedsugar/" className="hover:underline">Instagram</a>
                  </li>
                </ul>
              </div>
              <div>
                {/* <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white">Legal</h2>
                <ul className="text-white font-medium"> */}
                  {/* <li className="mb-4">
                    <a href="#" className="hover:underline">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="#" className="hover:underline">Terms &amp; Conditions</a>
                  </li> */}
                {/* </ul> */}
              </div>
            </div>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <div className="sm:flex sm:items-center sm:justify-between">
            <span className="text-sm text-white sm:text-center dark:text-white">© 2024 <a href="twistedsugar.com" className="hover:underline">Twisted Sugar™</a>. All Rights Reserved.</span>
            <div className="flex mt-4 sm:justify-center sm:mt-0">
              <a href="https://www.facebook.com/heytwistedsugar/" className="text-white hover:text-gray-900 dark:hover:text-white">
                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                  <path fillRule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clipRule="evenodd"/>
                </svg>
                <span className="sr-only">Facebook page</span>
              </a>
              <a href="https://www.instagram.com/heytwistedsugar/" className="text-white hover:text-gray-900 dark:hover:text-white ms-5">
                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 2.05c3.21 0 3.59.01 4.85.07 1.39.06 2.37.24 2.93.45.65.25 1.13.57 1.62 1.06.5.49.81.97 1.06 1.62.21.56.39 1.54.45 2.93.06 1.26.07 1.64.07 4.85s-.01 3.59-.07 4.85c-.06 1.39-.24 2.37-.45 2.93-.25.65-.57 1.13-1.06 1.62-.49.5-.97.81-1.62 1.06-.56.21-1.54.39-2.93.45-1.26.06-1.64.07-4.85.07s-3.59-.01-4.85-.07c-1.39-.06-2.37-.24-2.93-.45-.65-.25-1.13-.57-1.62-1.06-.5-.49-.81-.97-1.06-1.62-.21-.56-.39-1.54-.45-2.93-.06-1.26-.07-1.64-.07-4.85s.01-3.59.07-4.85c.06-1.39.24-2.37.45-2.93.25-.65.57-1.13 1.06-1.62.49-.5.97-.81 1.62-1.06.56-.21 1.54-.39 2.93-.45 1.26-.06 1.64-.07 4.85-.07zM12 0c-3.28 0-3.67.01-4.97.07-1.65.07-2.77.25-3.58.49-1.08.31-1.94.72-2.72 1.5S.38 3.27.07 4.95C.01 6.25 0 6.64 0 12s.01 5.75.07 7.05c.06 1.65.25 2.77.49 3.58.31 1.08.72 1.94 1.5 2.72s1.64 1.19 2.72 1.5c.81.24 1.93.42 3.58.49 1.3.06 1.69.07 4.97.07s3.67-.01 4.97-.07c1.65-.07 2.77-.25 3.58-.49 1.08-.31 1.94-.72 2.72-1.5s1.19-1.64 1.5-2.72c.24-.81.42-1.93.49-3.58.06-1.3.07-1.69.07-4.97s-.01-3.67-.07-4.97c-.06-1.65-.25-2.77-.49-3.58-.31-1.08-.72-1.94-1.5-2.72s-1.64-1.19-2.72-1.5c-.81-.24-1.93-.42-3.58-.49C15.67.01 15.28 0 12 0zm0 5.81a6.19 6.19 0 1 1 0 12.38 6.19 6.19 0 0 1 0-12.38zM18.75 6a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5z"/>
                </svg>
                <span className="sr-only">Instagram</span>
              </a>

            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
