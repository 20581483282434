import React from 'react';
import { createGlobalStyle } from 'styled-components';
import Header from './components/Header';
import Footer from './components/Footer';
import Homepage from './Pages/Homepage';
import Franchise from './Pages/Franchise';
import Catering from './Pages/Catering';
import Hiring from './Pages/joinOurTeam'
import Menu from './Pages/Menu';

import { BrowserRouter as Router, Route, Routes, BrowserRouter } from 'react-router-dom';

const GlobalStyle = createGlobalStyle`

  @import url('https://use.typekit.net/fgy5zme.css');

  body {
    font-family: 'brandon-grotesque', sans-serif; /* Use the Brandon Grotesque font */
    font-weight: 400;
    font-style: normal;
    margin: 0;
    padding: 0;
    font-size: 1.425rem;
  }
`;

const App = () => {
  return (
    <>
      <GlobalStyle />
      <div className='h-full bg-slate-50 bg-repeat max-w-[100%] mx-auto overflow-hidden'>
      <Header />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/franchise" element={<Franchise />} />
            <Route path="/catering" element={<Catering />} />
            <Route path="/joinourteam" element={<Hiring />} />
            <Route path="/menu" element={<Menu />} />
          </Routes>
        </BrowserRouter>
        <Footer />
        
      </div>
    </>
  );
};

export default App;
