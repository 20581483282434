import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import menuPDF from '../assets/img/jpg2pdf.pdf';

const Menu = () => {
  // Create an instance of the default layout plugin
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div className="container mx-auto px-4 py-8">
      <div style={{ marginBottom: '20px', marginTop: '20%', textAlign: 'center' }}>
        <button className="bg-black text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white" href="https://order.online/business/TwistedSugar-121912?utm_source=sdk&visitorId=18bda5615d37d2b1f">
          Find your location
        </button>
        <button
          className="bg-black text-white px-4 py-2 rounded-md ml-4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
        >
          Order online
        </button>
      </div>

      <div className="flex justify-center">
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
          <div style={{ width: '100%', maxWidth: '900px' }}>
            <Viewer fileUrl={menuPDF} plugins={[defaultLayoutPluginInstance]} />
          </div>
        </Worker>
      </div>
    </div>
  );
};

export default Menu;
